<template>
  <div>
    <Delete :dialog="dialogDelete" :id="deleteId" @dialog="setDialogDelete" />
    <AddToProject
      v-if="addId"
      :addId="addId"
      :dialog="dialogProject"
      @dialog="setDialogProject"
    />
    <div class="right">
      <div>
        <NoHeatmapCard v-if="heatmapStore.length === 0 && !loading" />

        <v-infinite-scroll
          height="100%"
          class="pt-4"
          width="100%"
          v-if="heatmapStore.length > 0 && !loading"
          :items="heatmaps"
          :onLoad="load"
        >
          <div class="px-4">
            <div class="heatmaps">
              <template v-for="(heatmap, index) in heatmaps" :key="heatmap.uid">
                <Card
                  :heatmapProp="heatmap"
                  @analyze="openHeatmap"
                  @share="shareHeatmap"
                  @addToProject="addToProject"
                  :eager="index < 51"
                />
              </template>
            </div>
          </div>
        </v-infinite-scroll>

        <!-- <v-lazy
          v-for="(heatmap, index) in heatmaps"
          :key="heatmap.uid"
          :min-height="200"
          :options="{}"
          transition="fade-transition"
        >
          <Card
            :heatmapProp="heatmap"
            @analyze="openHeatmap"
            @share="shareHeatmap"
            @addToProject="addToProject"
            :eager="index < 51"
          />
        </v-lazy> -->

        <!-- <div
          class="d-flex justify-center mt-6 mb-10"
          style="width: 100%"
          v-intersect="visibilityChanged"
          v-if="
            heatmapStore.length > 9 && !$store.state.heatmaps.end && !loading
          "
        >
        

          <v-progress-circular
            indeterminate
            v-if="$store.state.heatmaps.loading"
          ></v-progress-circular>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import Urlbox from "urlbox";
import Card from "@/components/heatmap-card/HeatmapCard.vue";
// import { db } from "@/firebase";
import { mapState } from "vuex";
import Delete from "@/components/delete/DeleteCard.vue";
import AddToProject from "@/components/addToProject/AddToProject.vue";
import NoHeatmapCard from "@/components/no-heatmap/NoHeatmap.vue";
import { useGoTo } from "vuetify";
export default {
  name: "HeatMaps",
  components: {
    Card,
    Delete,
    AddToProject,
    NoHeatmapCard,
  },
  props: {
    heatmaps: {
      type: Array,
    },
  },
  data() {
    return {
      addId: null,
      name: "Home",
      deleteId: null,
      dialog: false,
      dialogDelete: false,
      dialogProject: false,
      key: 0,
      project: {
        title: "Title of project",
      },
      shareId: 0,
      scrollY: 0,
      uploads: [],
      url: "",
      urlLoading: false,
    };
  },
  watch: {
    scroll: {
      handler: function () {
        if (this.scroll) {
          const goTo = useGoTo();
          goTo(0);
          this.$store.commit("scroll", false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
      loading: (state) => state.loading,
      heatmapStore: (state) => state.heatmaps.heatmaps,
      scroll: (state) => state.scroll,
      query: (state) => state.heatmaps.query,
    }),
    filterActive() {
      return (
        this.query.search ||
        this.query.application ||
        (this.query.tags && this.query.tags.length > 0) ||
        (this.query.types && this.query.types.length > 0) ||
        (this.query.owned_by && this.query.owned_by.length > 0)
      );
    },
    fire() {
      if (this.url != "") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  methods: {
    addToProject(id) {
      this.addId = id;
      this.dialogProject = true;
    },
    back() {
      this.uploads = [];
    },
    deleteHeatmap(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },
    async getURL(val) {
      this.urlLoading = true;

      // 1 fetch screenshot, add it to heatmap
      const urlbox = Urlbox(
        "8wII7dOU3EGNmOFr",
        "ebcd185c3d2f49b198a257624c141416"
      );
      const options = {
        format: "png",
        click_accept: true,
        hide_cookie_banners: true,
        cookie: ["cookie=OptIn=true"],
        url: val,
        full_page: true,
        height: "1080",
        width: "1920",
      };

      const screenshot = await urlbox.buildUrl(options);
      this.uploads.push({
        image_thumb: screenshot,
        title: "",
        project: "",
        requester: {
          email: "test@test.com",
        },
        upload: true,
        loading: true,
      });

      this.url = "";
      this.urlLoading = false;
    },
    onDrop(formData, files) {
      console.log(formData); // Can be posted to server
      console.log(files); // Can get access to things like file name and size
    },
    openHeatmap(id) {
      this.$router.push(`/heatmap/${id}`);
    },
    setDialog(val) {
      this.dialog = val;
    },
    setDialogDelete(val) {
      this.dialogDelete = val;
    },
    setDialogProject(val) {
      this.dialogProject = val;
    },

    shareHeatmap(val) {
      this.shareId = val;
      this.dialog = true;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async load({ done }) {
      await this.$store.dispatch("fetchHeatmaps");
      console.log("loading more heatmaps");
      done("ok");
    },
    // visibilityChanged(isVisible) {
    //   console.log("Load heatmaps");
    //   if (isVisible && !this.filterActive) {
    //     this.$store.dispatch("fetchHeatmaps");
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.heatmaps {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.right {
  .uploads {
    flex-wrap: wrap;
  }
}
</style>
