<template>
  <div v-if="$store.state.userProfile.superadmin" class="text-center">
    <v-tabs dark grow v-model="tab">
      <v-tab>Users</v-tab>
      <v-tab>Workspaces</v-tab>
      <v-tab>Heatmaps</v-tab>
    </v-tabs>

    <v-data-table
      v-if="tab === 0"
      dark
      dense
      disable-sort
      disable-filtering
      disable-pagination
      :headers="headers"
      :items="users"
      :loading="loading"
      :items-per-page="-1"
    ></v-data-table>

    <v-data-table
      v-if="tab === 1"
      dark
      dense
      disable-sort
      disable-filtering
      disable-pagination
      :headers="headers1"
      :items="workspaces"
      :loading="loading"
      :items-per-page="-1"
    ></v-data-table>

    <v-data-table
      v-if="tab === 2"
      dark
      dense
      disable-sort
      disable-filtering
      disable-pagination
      :headers="headers2"
      :items="$store.state.heatmaps"
      :loading="loading"
      :items-per-page="-1"
    ></v-data-table>
  </div>
</template>

<script>
import { db } from "@/firebase";
export default {
  name: 'SuperAdminMobile',
  
  data() {
    return {
      tab: 0,
      headers: [
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Workspace",
          value: "workspace",
        },
      ],
      headers2: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Requester", value: "requested_by_name", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      headers1: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Members",
          value: "members",
        },
      ],
      users: [],
      workspaces: [],
      loading: true,
    };
  },
  mounted() {
    const vm = this;
    setTimeout(function () {
      vm.fetchData();
    }, 2000);
  },
  methods: {
    fetchData() {
      const vm = this;
      if (this.$store.state.users.userProfile.superadmin) {
        db.collection("workspaces")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              vm.workspaces.push({
                name: doc.data().name,
                members: doc.data().members.length,
                uid: doc.data().uid,
              });
            });
          })
          .then(() => {
            db.collection("users")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  vm.users.push({
                    email: doc.data().email,
                    workspace: vm.workspaces.find(
                      (w) => w.uid === doc.data().workspace
                    ).name,
                  });
                });
              });
          })
          .then(() => {
            vm.loading = false;
          });
      }
    },
  },
};
</script>
