<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ props }">
      <v-btn
        elevation="0"
        color="success"
        class="text-warning"
        v-bind="props"
        height="40"
        :loading="urlLoading"
        :disabled="
          noCredits ||
          userProfile.trialEnded ||
          (subscription.active === false &&
            userProfile.workspace != '' &&
            workspace.beta != true)
        "
        >New heatmap</v-btn
      >
    </template>

    <v-card>
      <v-window v-model="step" style="height: 100%">
        <v-window-item :value="1" style="width: 100%">
          <v-card-title
            style="width: 100%"
            class="bg-primary py-4 text-white d-flex align-center"
          >
            <span>Create new heatmap</span>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                step = 1;
                menu = false;
              "
              dark
              variant="text"
              icon="mdi-close"
            ></v-btn
          ></v-card-title>
          <div class="options">
            <div
              class="option"
              @click="
                step = 2;
                type = 'image';
              "
            >
              <v-img
                width="70"
                class="mb-2"
                :src="require('@/assets/icons/image.png')"
              ></v-img>
              <p class="mb-0 font-weight-bold">Image</p>
              <p class="mb-2" style="font-size: 12px">png, jpg</p>
            </div>
            <div
              class="option"
              @click="
                step = 2;
                type = 'video';
              "
            >
              <v-img
                width="70"
                class="mb-2"
                :src="require('@/assets/icons/video.png')"
              ></v-img>
              <p class="mb-0 font-weight-bold">Video</p>
              <p class="mb-2" style="font-size: 12px">mp4</p>
            </div>
            <div
              class="option"
              @click="
                step = 2;
                type = 'website';
              "
            >
              <v-img
                width="70"
                class="mb-2"
                :src="require('@/assets/icons/website.png')"
              ></v-img>
              <p class="mb-0 font-weight-bold">Website</p>
              <p class="mb-2" style="font-size: 12px">url</p>
            </div>
            <div
              class="option"
              @click="
                step = 2;
                type = 'html5 banner';
              "
            >
              <v-img
                width="70"
                class="mb-2"
                :src="require('@/assets/icons/ads.png')"
              ></v-img>
              <p class="mb-0 font-weight-bold">HTML5 Banner</p>
              <p class="mb-2" style="font-size: 12px">html5 link</p>
            </div>
          </div>
        </v-window-item>
        <v-window-item :value="2" style="height: 100%">
          <v-card-title
            style="width: 100%"
            class="bg-primary py-4 text-white d-flex align-center"
          >
            <v-btn
              icon="mdi-chevron-left"
              variant="text"
              @click="step = 1"
              class="mr-2"
            >
            </v-btn>
            <span>New {{ type }} heatmap</span>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                step = 1;
                menu = false;
              "
              dark
              icon="mdi-close"
              variant="text"
            ></v-btn>
          </v-card-title>

          <div class="pa-6" v-if="type === 'image' || type === 'video'">
            <v-btn
              block
              color="warning"
              elevation="0"
              @click="trigger"
              height="44"
              >Pick file(s) from computer
              <v-icon small class="ml-2">mdi-upload</v-icon></v-btn
            >
            <div v-if="type === 'image'">
              <div class="d-flex align-center my-4">
                <v-divider></v-divider>
                <span class="mx-4">or</span>
                <v-divider></v-divider>
              </div>
              <v-text-field
                variant="solo"
                flat
                bg-color="smoke"
                persistent-hint
                hint="Add a url that hosts an image or video. Brainsight will get the file from the hosted location."
                placeholder="Add a url to a file"
                v-model="hosted"
              ></v-text-field>
            </div>
            <div class="d-flex align-center my-4">
              <v-divider></v-divider>
              <span class="mx-4">or</span>
              <v-divider></v-divider>
            </div>
            <div
              class="d-flex align-center justify-center py-3"
              style="border: 1px dashed #282447; border-radius: 6px"
            >
              <p class="ma-0 pa-0" style="font-size: 10pt; font-weight: 500">
                Drag and drop your file here.
              </p>
            </div>
          </div>

          <div v-if="type === 'website'" class="pa-6">
            <v-text-field
              variant="solo"
              bg-color="smoke"
              flat
              placeholder="www.google.com"
              color="warning"
              hide-details=""
              v-model="url.url"
            ></v-text-field>
            <div class="d-flex py-4 justify-space-between">
              <div class="d-flex align-center">
                <span class="mr-4">Desktop</span
                ><v-switch
                  color="primary"
                  inset
                  hide-details
                  v-model="url.mobile"
                ></v-switch>
                <span class="ml-3">Mobile</span>
              </div>
              <div class="d-flex align-center">
                <span class="mr-4">Above fold</span>
                <v-switch
                  color="primary"
                  inset
                  hide-details
                  v-model="url.fullPage"
                ></v-switch>
                <span class="ml-3">Full page</span>
              </div>
            </div>

            <div class="d-flex align-center">
              <v-divider></v-divider>
              <v-btn
                variant="tonal"
                class="mx-4"
                size="small"
                style="font-size: 9pt"
                @click="showAdvanced = !showAdvanced"
              >
                Advanced
                <v-icon class="ml-2">{{
                  showAdvanced ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
              <v-divider></v-divider>
            </div>
            <v-expand-transition>
              <div
                v-if="showAdvanced || url.fullPage"
                class="mt-4"
                style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px"
              >
                <div>
                  <p style="font-size: 8pt">Delay (ms):</p>
                  <v-text-field
                    type="number"
                    placeholder="1000"
                    variant="solo"
                    bg-color="smoke"
                    flat
                    v-model="url.delay"
                    hint="Time to wait (in ms) after page loaded before producing a render."
                    persistent-hint
                  ></v-text-field>
                </div>
                <div>
                  <p style="font-size: 8pt">Hide selector:</p>
                  <v-text-field
                    placeholder="#cookiebanner"
                    variant="solo"
                    bg-color="smoke"
                    flat
                    v-model="url.hide_selector"
                    hint="Hides HTML elements specified by this selector."
                    persistent-hint
                  ></v-text-field>
                </div>
                <div v-if="!url.fullPage">
                  <p style="font-size: 8pt">Scroll to:</p>
                  <v-text-field
                    placeholder="#element"
                    variant="solo"
                    bg-color="smoke"
                    flat
                    v-model="url.scroll_to"
                    hint="Scroll to an element or pixel offset from the top of the page."
                    persistent-hint
                  ></v-text-field>
                </div>
                <div v-if="url.fullPage" class="d-flex align-center">
                  <v-badge content="new" color="primary">
                    <v-checkbox
                      label="Add scrolling video"
                      color="primary"
                      v-model="url.add_scrolling_video"
                    >
                    </v-checkbox>
                  </v-badge>
                </div>
                <!-- <div class="d-flex align-center">
                  <v-badge content="beta" color="primary">
                    <v-checkbox
                      label="UX Audit"
                      color="primary"
                      v-model="url.audit"
                    ></v-checkbox>

                    ></v-badge
                  >
                </div> -->
              </div>
            </v-expand-transition>
          </div>

          <div class="pa-6" v-if="type === 'html5 banner'">
            <v-text-field
              class="mb-4"
              variant="solo"
              bg-color="smoke"
              flat
              placeholder="Heatmap title"
              color="warning"
              label="Title"
              hide-details=""
              v-model="banner_title"
            ></v-text-field>
            <v-text-field
              class="mb-4"
              variant="solo"
              bg-color="smoke"
              label="HTML5 Banner link"
              flat
              placeholder="www.hosting.com/banner.html"
              color="warning"
              hint="The URL should only contain the HTML5 banner."
              v-model="banner_link"
              persistent-hint
            ></v-text-field>
            <div class="d-flex mb-4 justify-space-between">
              <v-select
                label="Position"
                :items="positions"
                item-title="text"
                variant="solo"
                flat
                v-model="banner_position"
                bg-color="smoke"
                item-value="value"
                persistent-hint
                hint="If you open the URL of the hosted banner, where is it positioned on the screen?"
                style="max-width: 48%"
              ></v-select>
              <v-text-field
                style="max-width: 48%"
                variant="solo"
                flat
                bg-color="smoke"
                type="number"
                persistent-hint
                hint="For how many milliseconds does the banner play?"
                label="Duration of banner (milliseconds)"
                v-model="banner_duration"
              ></v-text-field>
            </div>
            <div class="d-flex justify-space-between">
              <v-text-field
                class="mb-4"
                variant="solo"
                bg-color="smoke"
                flat
                type="number"
                label="Banner width"
                placeholder="300"
                color="warning"
                hide-details=""
                style="max-width: 48%"
                v-model="banner_dimensions.width"
              ></v-text-field>
              <v-text-field
                class="mb-4"
                style="max-width: 48%"
                variant="solo"
                bg-color="smoke"
                type="number"
                flat
                label="Banner height"
                placeholder="600"
                color="warning"
                hide-details=""
                v-model="banner_dimensions.height"
              ></v-text-field>
            </div>
          </div>
        </v-window-item>
      </v-window>

      <div v-if="step > 1">
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="
              menu = false;
              step = 1;
            "
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="type === 'image'"
            @click="generateWithUrl"
            color="success"
            width="120"
            elevation="0"
            class="warning--text"
            >Create</v-btn
          >
          <v-btn
            v-else-if="type != 'html5 banner'"
            :disabled="disableURL"
            color="success"
            width="120"
            variant="flat"
            @click="getURL"
            elevation="0"
          >
            Create
          </v-btn>
          <v-btn
            :loading="banner_loading"
            :disabled="disabledBanner"
            color="success"
            width="120"
            elevation="0"
            @click="getBanner"
            v-else
            >Create banner</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import { uploads } from "@/mixins/uploads";

export default {
  name: "NewHeatmap",
  props: ["urlLoading"],
  mixins: [uploads],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
      loading: (state) => state.loading,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tagStore: (state) => state.heatmaps.tags,
      subscription: (state) => state.subscription.subscription,
      userStore: (state) => state.users.users,
    }),
    noCredits() {
      if (
        this.userProfile.role === "freemium" ||
        this.userProfile.role === "trial"
      ) {
        return (
          this.userProfile.imageCredits === 0 &&
          this.userProfile.videoCredits === 0
        );
      } else {
        return (
          this.workspace.imageCredits === 0 && this.workspace.videoCredits === 0
        );
      }
    },
    disabledBanner() {
      let disabled = false;

      if (!this.banner_title) {
        disabled = true;
      }

      if (!this.banner_link) {
        disabled = true;
      }

      if (!this.banner_duration) {
        disabled = true;
      }

      if (!this.banner_position) {
        disabled = true;
      }

      if (!this.banner_dimensions.width) {
        disabled = true;
      }

      if (!this.banner_dimensions.height) {
        disabled = true;
      }
      return disabled;
    },
    disableURL() {
      let disabled = false;
      if (this.url.url === "") {
        disabled = true;
      }

      if (
        this.userProfile.role === "trial" &&
        this.userProfile.imageCredits < 1
      ) {
        disabled = true;
      }
      if (this.userProfile.workspace && this.workspace.imageCredits < 1) {
        disabled = true;
      }
      return disabled;
    },
  },
  data() {
    return {
      banner_loading: false,
      banner_title: "",
      banner_link: "",
      banner_duration: 5000,
      banner_position: "center",
      banner_dimensions: {
        width: null,
        height: null,
      },
      hosted: null,
      positions: [
        {
          text: "Left top",
          value: "lefttop",
        },
        {
          text: "Right top",
          value: "righttop",
        },
        {
          text: "Center",
          value: "center",
        },
        {
          text: "Left bottom",
          value: "leftbottom",
        },
        {
          text: "Right bottom",
          value: "rightbottom",
        },
      ],
      menu: false,
      url: {
        fullPage: false,
        mobile: false,
        url: "",
        delay: null,
        hide_selector: null,
        audit: false,
        add_scrolling_video: false,
      },
      showAdvanced: false,
      step: 1,
      type: null,
    };
  },
  methods: {
    async getBanner() {
      this.banner_loading = true;
      const heatmap = {
        application: "Display Ads",
        dimensions: `${this.banner_dimensions.width}x${this.banner_dimensions.height}`,
        title: this.banner_title,
        src: this.banner_link,
        type: "video",
        subtype: "html5",
        duration: this.banner_duration,
        position: this.banner_position,
        public: false,
        project: "",
        admin: this.userProfile,
      };
      console.log(heatmap);

      this.$store.dispatch("requestBanner", heatmap);
      this.banner_loading = false;
      this.banner_title = "";
      this.banner_link = "";
      this.banner_duration = 5000;
      this.banner_position = "center";
      this.banner_dimensions = {
        width: null,
        height: null,
      };
      this.step = 1;
      this.banner_loading = false;
      this.menu = false;
    },
    getURL() {
      const url = { ...this.url };
      this.$store.dispatch("requestUrl", url);
      this.url = {
        fullPage: false,
        mobile: false,
        url: "",
        delay: null,
        hide_selector: null,
        audit: false,
        add_scrolling_video: false,
      };
      this.step = 1;
      this.menu = false;
      this.showAdvanced = false;
    },
    async generateWithUrl() {
      console.log("generating heatmap from url");

      const image = await fetch(this.hosted);
      const blob = await image.blob();
      const myRenamedFile = new File(
        [blob],
        `upload.${blob.type.split("/")[1]}`,
        {
          type: blob.type,
        }
      );
      let res = await this.toBase64(blob);
      this.uploads.push({
        original: myRenamedFile,
        thumbnail: res,
        type: blob.type.split("/")[0],
        title: "Title",
        projectIds: [],
        loading: false,
      });
      this.hosted = null;
      this.uploads_dialog = true;
      this.menu = false;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    trigger() {
      this.step = 1;
      this.$emit("trigger");
      this.menu = false;
    },
    updateDevice(v) {
      if (v) {
        this.url.mobile = true;
        this.url.desktop = false;
      } else if (!v) {
        this.url.mobile = false;
        this.url.desktop = true;
      }
    },
    updateScreen(v) {
      if (v) {
        this.url.fullPage = true;
        this.url.fold = false;
      } else if (!v) {
        this.url.fullPage = false;
        this.url.fold = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 140px;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 140px;
  border: 2px solid white;
  background-color: #8b78fa;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  margin-bottom: 25px;

  p {
    margin: 0;
  }
}

.divider {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  span {
    text-align: center;
    font-size: 10pt;
  }
}

.filters {
  .v-btn {
    text-transform: none;
    font-weight: 450;
    font-size: 0.75rem;
    background-color: white;
  }
}

.v-btn {
  text-transform: none;
  font-weight: 600;
  font-size: 0.875rem;
}

.url {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.new-project {
  display: flex;
  gap: 10px;
}

input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    &:hover {
      background-color: #f4f4f4;
      cursor: pointer;
    }
  }
}
</style>
