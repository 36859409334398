<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ props }">
      <v-list-item
        v-bind="props"
        link
        v-if="heatmap.metrics.percentile_area >= 0"
      >
        <div class="d-flex align-center">
          <div
            class="mr-2 white--text font-weight-medium"
            style="font-size: 10pt; width: 100px"
          >
            Focus
          </div>

          <v-progress-linear
            rounded
            :model-value="100 - heatmap.metrics.percentile_area"
            :color="areaColor"
          ></v-progress-linear>
        </div>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="bg-accent d-flex">
        <h2 class="white--text">Focus</h2>
        <v-spacer></v-spacer>
        <v-btn
          icon="mdi-close"
          variant="text"
          color="white"
          @click="dialog = false"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="pa-4">
        <div
          style="display: grid; gap: 20px"
          :style="{
            'grid-template-columns':
              heatmap.type === 'video' ? '1fr' : '1fr 1fr',
          }"
        >
          <div>
            <p style="font-size: 14pt">
              With a Focus score of
              <span class="font-weight-bold">{{
                100 - heatmap.metrics.percentile_area
              }}</span
              >, the focus within the asset is

              <span
                class="font-weight-bold"
                v-if="100 - heatmap.metrics.percentile_area > 50"
                >high</span
              >
              <span
                class="font-weight-bold"
                v-else-if="100 - heatmap.metrics.percentile_area > 25"
                >average</span
              >
              <span
                class="font-weight-bold"
                v-else-if="100 - heatmap.metrics.percentile_area < 26"
                >low</span
              >

              compared to our
              <span class="font-weight-bold">{{ heatmap.application }}</span>
              benchmark.
            </p>
          </div>
          <v-img
            v-if="heatmap.type === 'image'"
            max-height="250"
            contain
            style="border-radius: 6px"
            :src="heatmap.peekthrough"
          ></v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <h4 class="mb-2">About Focus</h4>
        <p style="font-size: 11pt" class="mb-0">
          Focus is an index of focused attention in an image or a video. In
          other words, it is an index of how large a portion of your asset draws
          attention. It also indicates the level of consensus between viewers on
          where they will spend their time looking at an image or video frame.
          If your image or video has many items that draw attention, people are
          less likely to focus on any single part of the asset.
        </p>
      </div>
      <v-divider></v-divider>
      <v-card-actions
        @click="
          openGit(
            'https://braingineers.gitbook.io/brainsight/metrics/clarity/how-it-is-calculated#2.-measure-focus'
          )
        "
      >
        <v-btn text> How Focus is calculated </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["heatmap"],
  computed: {
    areaColor() {
      let score = 100 - this.heatmap.metrics.percentile_area;
      if (score >= 50) {
        return "success lighten-1";
      } else if (score < 26) {
        return "error lighten-1";
      } else {
        return "info lighten-1";
      }
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openGit(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
