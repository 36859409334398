<template>
  <div class="mt-4" style="margin-bottom: 100px">
    <v-card flat class="pa-6" max-width="1000px">
      <div class="mt-4 mb-6 d-flex align-center justify-space-between">
        <div>
          <v-badge floating content="beta" color="primary">
            <h2>UX Audit</h2>
          </v-badge>
          <p style="font-size: 10pt; max-width: 70%">
            An automated UX audit by a customized GPT-4o, based on the website
            and the heatmap data above, predicting the first 5 seconds of
            attention.
          </p>
        </div>
        <v-btn @click="show = !show" variant="tonal"
          ><v-icon class="mr-2">{{
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon
          >{{ show ? "Close" : "Open" }}</v-btn
        >
      </div>
      <v-expand-transition>
        <div v-if="show && heatmap">
          <div>
            <v-divider></v-divider>
            <h3 class="mt-10">1. Associations</h3>
            <p class="mb-4" style="font-size: 10pt">
              Associations related to this page after looking for maximum of 5
              seconds.
            </p>
            <div class="d-flex flex-wrap" style="gap: 10px">
              <v-chip
                v-for="(i, index) in heatmap.audit_scores.associations"
                :key="index"
                >{{ i }}</v-chip
              >
            </div>
          </div>

          <v-divider class="my-10"></v-divider>
          <h3 class="mt-6">2. Clarity of the website</h3>
          <p class="mb-4" style="font-size: 10pt">
            Benchmarked metrics to determine the cognitive ease for this
            website.
          </p>
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px">
            <v-card class="pa-6 bg-accent">
              <p>Focus</p>
              <v-progress-linear
                :color="getColor((100 - heatmap.metrics.percentile_area) / 10)"
                :model-value="100 - heatmap.metrics.percentile_area"
                rounded
                class="my-2"
              ></v-progress-linear>
            </v-card>

            <v-card class="pa-6 bg-accent">
              <p>Spread</p>
              <v-progress-linear
                :color="getColor(heatmap.metrics.percentile_distances / 10)"
                :model-value="heatmap.metrics.percentile_distances"
                rounded
                class="my-2"
              ></v-progress-linear>
            </v-card>
          </div>

          <v-divider class="my-10"></v-divider>
          <h3 class="mt-6">3. Goal oriented scores</h3>
          <p class="mb-4" style="font-size: 10pt; max-width: 70%">
            Based on scaled questions to valuate the key components of a
            website. Remember, they are answered based on the first 5 seconds.
          </p>
          <div
            style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px"
            class="mt-6"
          >
            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.offer.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.offer.rating)"
                  :model-value="heatmap.audit_scores.offer.rating * 10"
                  rounded
                ></v-progress-linear>
              </div>
              <div>
                <div class="mb-1 d-flex align-center">
                  <span><b>What is the page about?</b></span>
                  <v-tooltip text="Tooltip">
                    <template v-slot:activator="{ props }">
                      <v-icon color="grey" class="ml-1" size="16" v-bind="props"
                        >mdi-information</v-icon
                      >
                    </template>
                  </v-tooltip>
                </div>

                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.offer.reasoning }}
                </p>

                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.offer.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>

            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.goNext.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.goNext.rating)"
                  :model-value="heatmap.audit_scores.goNext.rating * 10"
                  rounded=""
                ></v-progress-linear>
              </div>
              <div>
                <p class="mb-1"><b>Where should I go next?</b></p>
                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.goNext.reasoning }}
                </p>

                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.goNext.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>

            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.why.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.why.rating)"
                  :model-value="heatmap.audit_scores.why.rating * 10"
                  rounded=""
                ></v-progress-linear>
              </div>
              <div>
                <p class="mb-1"><b>What makes me want to stay?</b></p>
                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.why.reasoning }}
                </p>
                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.why.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>

            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.brand.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.brand.rating)"
                  :model-value="heatmap.audit_scores.brand.rating * 10"
                  rounded=""
                ></v-progress-linear>
              </div>
              <div>
                <p class="mb-1">
                  <b>Who is behind the website?</b>
                </p>
                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.brand.reasoning }}
                </p>
                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.brand.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>

            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.readability.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.readability.rating)"
                  :model-value="heatmap.audit_scores.readability.rating * 10"
                  rounded=""
                ></v-progress-linear>
              </div>
              <div>
                <p class="mb-1"><b>Readability</b></p>
                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.readability.reasoning }}
                </p>
                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.readability.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>
            <v-card
              flat
              class="pa-4"
              style="display: grid; grid-template-columns: 100px auto"
            >
              <div class="pr-4">
                <p>
                  <span style="font-size: 30pt; font-weight: 600">{{
                    heatmap.audit_scores.trustworthiness.rating
                  }}</span
                  ><span>/10</span>
                </p>
                <v-progress-linear
                  :color="getColor(heatmap.audit_scores.trustworthiness.rating)"
                  :model-value="
                    heatmap.audit_scores.trustworthiness.rating * 10
                  "
                  rounded=""
                ></v-progress-linear>
              </div>
              <div>
                <p class="mb-1"><b>Trustworthiness</b></p>
                <p style="font-size: 10pt">
                  {{ heatmap.audit_scores.trustworthiness.reasoning }}
                </p>
                <p class="mt-2" style="font-size: 10pt">
                  <v-icon size="14">mdi-arrow-right</v-icon>
                  <i>
                    {{ heatmap.audit_scores.trustworthiness.suggestion }}
                  </i>
                </p>
              </div>
            </v-card>
          </div>
          <!-- <v-divider class="mt-10"></v-divider>
          <div class="my-10 d-flex justify-center">
            <div style="width: 400px">
              <div class="d-flex justify-space-between align-center mb-2">
                <img
                  class="mb-2"
                  src="@/assets/logo-dark.svg"
                  style="width: 30px"
                  alt=""
                />
                <span
                  >Help us improve this
                  <v-chip color="primary" variant="flat" class="mx-1">
                    beta</v-chip
                  >
                  feature</span
                >
              </div>

              <v-textarea
                variant="solo"
                flat
                bg-color="smoke"
                label="Feedback"
                hide-details=""
                class="mb-2"
                v-model="feedback"
              ></v-textarea>
              <v-btn
                :disabled="!feedback"
                color="primary"
                flat
                variant="tonal"
                block=""
                >Send feedback</v-btn
              >
            </div>
          </div> -->
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["heatmap"],
  data() {
    return {
      feedback: "",
      show: true,
    };
  },
  methods: {
    getColor(value) {
      if (value < 4) {
        return "error";
      } else if (value < 6) {
        return "info";
      } else {
        return "success";
      }
    },
  },
};
</script>
