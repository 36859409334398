<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ props }">
      <v-list-item
        class="myClass"
        link
        v-bind="props"
        v-if="
          heatmap.metrics.percentile_distances ||
          heatmap.metrics.percentile_distances === 0
        "
      >
        <div class="d-flex align-center">
          <div
            class="mr-2 text-white font-weight-medium"
            style="font-size: 10pt; width: 100px"
          >
            Spread
          </div>

          <v-progress-linear
            rounded
            :model-value="heatmap.metrics.percentile_distances"
            :color="distancesColor"
          ></v-progress-linear>
        </div>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="bg-accent d-flex">
        <h2 class="white--text">Spread</h2>
        <v-spacer></v-spacer>
        <v-btn
          icon="mdi-close"
          variant="text"
          color="white"
          @click="dialog = false"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="pa-4">
        <div
          style="display: grid; gap: 20px"
          :style="{
            'grid-template-columns':
              heatmap.type === 'video' ? '1fr' : '1fr 1fr',
          }"
        >
          <div>
            <p style="font-size: 14pt">
              With a Spread score of
              <span class="font-weight-bold">{{
                heatmap.metrics.percentile_distances
              }}</span
              >, the attention grabbing elements within the asset are

              <span
                class="font-weight-bold"
                v-if="100 - heatmap.metrics.percentile_distances > 50"
                >close to each other</span
              >
              <span
                class="font-weight-bold"
                v-else-if="100 - heatmap.metrics.percentile_distances > 25"
                >average distance from each other</span
              >
              <span
                class="font-weight-bold"
                v-else-if="100 - heatmap.metrics.percentile_distances < 26"
                >distant from each other</span
              >

              compared to our
              <span class="font-weight-bold">{{ heatmap.application }}</span>
              benchmark.
            </p>
          </div>
          <v-img
            v-if="heatmap.type === 'image'"
            max-height="250"
            contain
            style="border-radius: 6px"
            :src="heatmap.gazeplot"
          ></v-img>
        </div>
      </div>

      <v-divider></v-divider>
      <div class="pa-4">
        <h4 class="mb-2">About Spread</h4>
        <p style="font-size: 11pt" class="mb-0">
          Spread is an index of the distance between the attention grabbing
          elements in an image or video. It indicates if the viewer has to scan
          a lot through the image/video to see all the important elements. If
          the distance between the elements becomes greater it will be less easy
          for a viewer to process the image and to continue processing the
          image.
        </p>
      </div>
      <v-divider></v-divider>
      <v-card-actions
        @click="
          openGit(
            'https://braingineers.gitbook.io/brainsight/metrics/clarity/how-it-is-calculated#3.-measure-spread'
          )
        "
      >
        <v-btn text> How Spread is calculated </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HeatmapSpreadcard",
  props: ["heatmap"],
  computed: {
    distancesColor() {
      let score = this.heatmap.metrics.percentile_distances;
      if (score >= 75) {
        return "error lighten-1";
      } else if (score < 75 && score > 49) {
        return "info lighten-1";
      } else {
        return "success lighten-1";
      }
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openGit(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
