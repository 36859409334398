import { db, auth } from "@/firebase";
import firebase from "firebase/";
import store from "@/store";

const admin = {
  state: {
    models: [],
    workspaces: [],
    users: [],
    stats: null,
    stripe: null,
  },
  mutations: {
    setModels(state, val) {
      state.models = val;
    },
    setAdminWorkspaces(state, val) {
      state.workspaces = val;
    },
    setAdminUsers(state, val) {
      state.users = val;
    },
    setAdminStats(state, val) {
      state.stats = val;
    },
    setAdminStripe(state, val) {
      state.stripe = val;
    },
  },
  actions: {
    async fetchModels({ commit }) {
      return new Promise(async (resolve) => {
        let models = [];
        let r = await auth.currentUser.getIdTokenResult();

        if (r.claims.admin) {
          console.log("Fetching models");
          const querySnapshot = await db.collection("models").get();
          await Promise.all(
            querySnapshot.docs.map(async (m) => {
              let model = m.data();

              if (!model.last_update) {
                model.old_heatmaps = model.heatmaps;
              }
              model.heatmaps = [];

              let heatmapSnapshot = await db
                .collection("models")
                .doc(model.uid)
                .collection("labels")
                .get();

              heatmapSnapshot.docs.map(async (h) => {
                let hm = await db.collection("heatmaps").doc(h.id).get();
                let heatmap = hm.data();
                heatmap.labels = [];
                heatmap.labels = h.data().labels;
                model.heatmaps.push(heatmap);
              });

              const objectsSnapshot = await db
                .collection("models")
                .doc(model.uid)
                .collection("objects")
                .get();
              model.objects = [];
              objectsSnapshot.docs.map((o) => {
                model.objects.push(o.data());
              });
              models.push(model);
            })
          );
        }
        models.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : b.name.toLowerCase() < a.name.toLowerCase()
              ? 1
              : 0
        );

        commit("setModels", models);
        resolve();
      });
    },
    async fetchAdminWorkspaces({ commit, rootState }) {
      return new Promise(async (resolve) => {
        let workspaces = [];

        if (rootState.users.userProfile.superadmin) {
          const querySnapshot = await db
            .collection("workspaces")
            .orderBy("name")
            .get();
          await Promise.all(
            querySnapshot.docs.map(async (m) => {
              let ws = m.data();
              const admn = await db.collection("users").doc(ws.admin).get();
              ws.members_data = [];

              await Promise.all(
                ws.members.map(async (mem) => {
                  const memDoc = await db.collection("users").doc(mem).get();
                  if (memDoc.exists) {
                    ws.members_data.push(memDoc.data());
                  }
                })
              );

              const querySnapshot = await db
                .collection("customers")
                .doc(ws.admin)
                .collection("subscriptions")
                .get();

              if (admn.exists) {
                ws.admin_data = admn.data();
              }
              querySnapshot.forEach((doc) => {
                let i = doc
                  .data()
                  .items.findIndex((p) => p && p.plan && p.plan.active);

                ws.subscription = {
                  active: i >= 0,
                  status: doc.data().status,
                  current_period_end: doc.data().current_period_end,
                };
              });

              workspaces.push(ws);
            })
          );
        }

        commit("setAdminWorkspaces", workspaces);
        resolve();
      });
    },
    async fetchAdminUsers({ commit }) {
      return new Promise(async (resolve) => {
        const querySnapshot = await db
          .collection("users")
          .orderBy("name")
          .get();
        let users = [];
        await Promise.all(
          querySnapshot.docs.map(async (u) => {
            let user = u.data();
            if (user.workspace) {
              const memDoc = await db
                .collection("workspaces")
                .doc(user.workspace)
                .get();
              user.workspace_data = memDoc.data();
            }

            users.push(user);
          })
        );

        commit("setAdminUsers", users);
        resolve();
      });
    },
    async fetchAdminStats({ commit }) {
      const querySnapshot = await db.collection("stats").get();
      let stats = [];
      await Promise.all(
        querySnapshot.docs.map(async (u) => {
          stats.push(u.data());
        })
      );

      commit("setAdminStats", stats);
    },
    async fetchAdminStripe({ commit }) {
      var getStripe = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("stripeGetOptions");

      const res = await getStripe();
      commit("setAdminStripe", res.data);
    },
    async addSubscription({ commit }, val) {
      return new Promise(async (resolve) => {
        var addSub = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("stripeAddSubscription");

        const res = await addSub(val);
        console.log(res);
        commit("setSnackbar", {
          text:
            res.data === 200
              ? "Subscription added!"
              : "Something went wrong.. Check Stripe manually.",
          timeout: 3000,
          show: true,
        });
        resolve();
      });
    },
    async predict({ commit }, val) {
      commit("setLoading", true);

      const m = await db.collection("models").doc(val.model.uid).get();
      let model = m.data();

      if (!model.last_update) {
        model.old_heatmaps = model.heatmaps;
      }
      model.heatmaps = [];

      let heatmapSnapshot = await db
        .collection("models")
        .doc(model.uid)
        .collection("labels")
        .get();

      await Promise.all(
        heatmapSnapshot.docs.map(async (h) => {
          let hm = await db.collection("heatmaps").doc(h.id).get();
          let heatmap = hm.data();
          heatmap.labels = [];
          heatmap.labels = h.data().labels;
          model.heatmaps.push(heatmap);
        })
      );

      const objectsSnapshot = await db
        .collection("models")
        .doc(model.uid)
        .collection("objects")
        .get();
      model.objects = [];
      await Promise.all(
        objectsSnapshot.docs.map((o) => {
          model.objects.push(o.data());
        })
      );

      if (val.heatmap.logo_tracking_status) {
        await db.collection("heatmaps").doc(val.heatmap.uid).update({
          logo_tracking_status: "processing",
          logo_tracking_video: null,
        });
      }

      let texts = [];
      if (model.objects.filter((o) => o.type === "text").length > 0) {
        model.objects.map((o) => {
          if (o.type === "text") {
            texts.push(o.name);
          }
        });
      }
      let onlyText =
        model.objects.filter((o) => o.type === "image").length === 0;
      let reqData = {
        remote_model_path: onlyText
          ? ""
          : `models/${model.uid}-v${model.version}.pt`,
        remote_path: `${val.heatmap.requested_by}/${val.heatmap.uid}`,
        filepath: `${val.heatmap.uid}.${val.heatmap.extension}`,
        worker: "predict",
        text: texts,
      };
      console.log(reqData);

      var requestPrediction = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("requestPrediction");

      const res = await requestPrediction(reqData);
      console.log(res.data);

      commit("setLoading", false);
      store.commit("setSnackbar", {
        text: "Object Recognition Requested",
        timeout: 2000,
        show: true,
      });
    },
  },
};

export default admin;
