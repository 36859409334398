import { createStore } from "vuex";
import * as fb from "../firebase";
import router from "../router/index";
import { db, auth } from "@/firebase";
import firebase from "firebase/";

// Import stores
import subscription from "./subscription";
import workspace from "./workspace";
import reports from "./report";
import defaults from "./defaults";
import userStore from "./users";
import projects from "./projects";
import heatmaps from "./heatmaps";
import blogs from "./blogs";
import docs from "./docs";
import admin from "./admin";
import uploads from "./uploads";

export default createStore({
  state: {
    loading: true,
    demo: {},
    scroll: false,
    tutorial: {
      dialog: false,
      id: null,
    },
    tutorials: [],
    snackbar: {
      text: "",
      timeout: 2000,
      show: false,
    },
  },
  mutations: {
    setSnackbar(state, val) {
      state.snackbar = val;
    },
    setTutorial(state, val) {
      state.tutorial = val;
    },
    setTutorials(state, val) {
      state.tutorials = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    setIntro(state) {
      state.users.userProfile.intro = true;
    },
    setDemo(state, val) {
      state.demo = val;
    },
    scroll(state, val) {
      state.scroll = val;
    },

    deductCredit(state, val) {
      if (state.users.userProfile.workspace) {
        if (val === "image") {
          state.workspace.workspace.imageCredits =
            state.workspace.workspace.imageCredits - 1;
        } else if (val === "video") {
          state.workspace.workspace.videoCredits =
            state.workspace.workspace.videoCredits - 1;
        }
      } else {
        if (val === "image") {
          state.users.userProfile.imageCredits =
            state.users.userProfile.imageCredits - 1;
        } else if (val === "video") {
          state.users.userProfile.videoCredits =
            state.users.userProfile.videoCredits - 1;
        }
      }
    },
  },
  actions: {
    async checkRequests({ state }) {
      const vm = this;
      console.log("Initializing realtime heatmap updates");
      let superadmin = false;
      if (state.users.userProfile.superadmin) {
        superadmin = true;
      }
      let member = false;
      if (
        state.users.userProfile.role === "member" ||
        state.users.userProfile.role === "admin"
      ) {
        member = true;
      }

      let query = db
        .collection("heatmaps")
        .where("requested_by", "==", auth.currentUser.uid)
        .where("workspace", "==", state.users.userProfile.workspace)
        .where("hidden", "==", false)
        .orderBy("created_at", "desc");

      if (superadmin) {
        query = db
          .collection("heatmaps")
          .where("hidden", "==", false)
          .orderBy("created_at", "desc");
      } else if (member && state.users.userProfile.workspace != "") {
        query = db
          .collection("heatmaps")
          .where("workspace", "==", state.users.userProfile.workspace)
          .where("hidden", "==", false)
          .orderBy("created_at", "desc");
      }

      query.limit(100).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          let item = change.doc.data();
          item.uid = change.doc.id;

          // if (change.type === "added") {
          //   vm.commit("addHeatmaps", item);
          // }
          if (change.type === "modified") {
            console.log("modified:");
            console.log(item);
            vm.commit("updateHeatmap", item);
            if (
              change.doc.data().reports &&
              change.doc.data().reports.length > 0
            ) {
              await vm.dispatch("fetchReports");
            }
          }
          if (change.type === "removed") {
            vm.commit("removeHeatmap", change.doc.id);
          }
        });
      });
    },
    async login({ dispatch }, form) {
      console.log("logging in");
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );

      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    },

    async signup({ dispatch, state }, form) {
      console.log("Signing up new user");
      const vm = this;

      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );
      console.log(state.defaults.defaults);
      var endDate = new Date();
      const days = state.defaults.defaults.trial.days;
      endDate.setDate(endDate.getDate() + days);

      console.log(state.defaults.defaults.trial);

      fb.usersCollection
        .doc(user.uid)
        .set({
          firstName: form.firstName,
          lastName: form.lastName,
          name: form.name,
          uid: user.uid,
          intro: false,
          workspace: form.workspace,
          email: user.email,
          keyword: form.keyword,
          interested_in: form.interested_in,
          avatar: "",
          role: form.role,
          phone: "",
          source: form.source,
          trialStart: new Date(),
          trialEnd: endDate,
          imageHeatmaps: 0,
          imageCredits: form.workspace
            ? 0
            : vm.state.defaults.defaults.trial.images,
          videoCredits: form.workspace
            ? 0
            : vm.state.defaults.defaults.trial.videos,
          videoHeatmaps: 0,
          visitedSubscriptions: 0,
        })
        .then(() => {
          // if Workspace comes through it meanse they have received an invite
          if (form.workspace) {
            var workspaceRef = db.collection("workspaces").doc(form.workspace);
            workspaceRef.update({
              members: firebase.firestore.FieldValue.arrayUnion(user.uid),
              seats: firebase.firestore.FieldValue.increment(-1),
            });
          }
        })
        .then(() => {
          dispatch("fetchUserProfile", user);
        });
    },
    async acceptInvite({ state }, val) {
      const vm = this;
      console.log(state.users.userProfile.uid);
      db.collection("users")
        .doc(val.uid)
        .update({
          workspace: val.workspace,
          role: "member",
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      db.collection("workspaces")
        .doc(val.workspace)
        .update({
          members: firebase.firestore.FieldValue.arrayUnion(val.uid),
          seats: firebase.firestore.FieldValue.increment(-1),
        })
        .then(() => {
          vm.dispatch("fetchUserProfile", val);
        });

      db.collection("heatmaps")
        .where("requested_by", "==", val.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (val.currentWorkspace != "") {
              db.collection("heatmaps")
                .doc(doc.id)
                .update({
                  requested_by: "",
                })
                .then(() => {
                  console.log("Updated workspace field on heatmap");
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("projects")
        .where("creator_uid", "==", val.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (val.currentWorkspace != "") {
              db.collection("projects")
                .doc(doc.id)
                .update({
                  creator_uid: "",
                })
                .then(() => {
                  console.log("Updated workspace field on heatmap");
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("reports")
        .where("created_by_uid", "==", val.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (val.currentWorkspace != "") {
              db.collection("reports")
                .doc(doc.id)
                .update({
                  created_by_uid: "",
                })
                .then(() => {
                  console.log("Updated workspace field on heatmap");
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      if (val.currentWorkspace) {
        db.collection("workspaces")
          .doc(val.workspace)
          .update({
            members: firebase.firestore.FieldValue.arrayRemove(val.uid),
            seats: firebase.firestore.FieldValue.increment(1),
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }

      if (val.email) {
        db.collection("workspaces")
          .doc(val.workspace)
          .update({
            invites: firebase.firestore.FieldValue.arrayRemove(val.email),
          })
          .then(() => {
            console.log("Removed from invites list");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }
    },
    async logout({ commit }, uid) {
      console.log("logging out");
      console.log(uid);
      await fb.auth.signOut();
      commit("setUserProfile", {});
      commit("setHeatmaps", []);
      commit("setProjects", []);
      commit("setTags", []);
      commit("setUsers", []);
      commit("setReports", []);
      commit("setWorkspace", {});
      commit("setDemo", {});
      commit("setSubscription", {});
      commit("setTutorial", {});
      commit("setTutorials", []);
      commit("setLast", null);
      commit("resetQuery");
      router.replace("/login");
    },
  },
  modules: {
    heatmaps,
    subscription,
    defaults,
    projects,
    reports,
    users: userStore,
    workspace,
    blogs,
    docs,
    uploads,
    admin,
  },
  getters: {},
});
