<template>
  <div>
    <SubscriptionDialog
      @close="sub_dialog = false"
      :dialog="sub_dialog"
      :user="user"
    />

    <div>
      <div class="bg-smoke align-center d-flex pa-2" style="height: 72px">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          bg-color="smoke"
          variant="solo"
          flat
          density="compact"
          hide-details
          style="max-width: 300px"
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-btn
          variant="tonal"
          class="mr-2"
          size="small"
          color="error"
          v-if="selected.length > 0 && !user"
          @click="deleteUsers(selected)"
          >Delete</v-btn
        >

        <v-menu
          v-model="invite"
          v-if="selected.length === 0 && !user"
          :close-on-content-click="false"
          location="end"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="tonal"
              class="mr-2"
              size="small"
              v-bind="props"
              color="primary"
              >Invite</v-btn
            >
          </template>

          <v-card min-width="300">
            <div class="pa-4">
              <v-text-field
                variant="solo"
                flat
                class="mb-2"
                bg-color="smoke"
                hide-details
                v-for="(i, index) in invites"
                :key="index"
                placeholder="name@company.com"
                v-model="i.email"
                :append-inner-icon="index > 0 ? 'mdi-delete' : ''"
                @click:append-inner="invites.splice(index, 1)"
              ></v-text-field>
              <v-btn
                @click="invites.push({ email: '' })"
                block
                density="compact"
                style="font-size: 8pt"
                variant="text"
                flat
                >Add</v-btn
              >
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                variant="text"
                @click="
                  invite = false;
                  invites = [{ email: '' }];
                "
              >
                Cancel
              </v-btn>
              <v-btn color="primary" variant="text" @click="sendInvites">
                Send invites
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <!-- <v-menu bottom left offset-y v-if="!user">
          <template v-slot:activator="{ props }">
            <v-btn
              class="ml-6"
              variant="text"
              size="small"
              icon="mdi-dots-vertical"
              v-bind="props"
              elevation="0"
            >
            </v-btn>
          </template>

          <v-list>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              width="200px"
              v-if="selected.length > 0"
            >
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" link class="px-6 py-2"
                  >Add to Mailshake
                </v-list-item>
              </template>

              <v-card width="200px">
                <v-list>
                  <v-list-item>
                    <v-select
                      :loading="loading"
                      class="mx-4 my-2"
                      label="Select campaign"
                      :items="campaigns"
                      item-text="title"
                      return-object
                      hide-details=""
                      v-model="campaignId"
                    ></v-select>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click="menu = false"> Cancel </v-btn>
                  <v-btn
                    color="primary"
                    :loading="mailshake_loading"
                    text
                    @click="mailshake()"
                  >
                    Add
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-list-item link class="px-6 py-2" v-if="selected.length > 0"
              >Remove users
            </v-list-item>
            <v-list-item link class="px-6 py-2">Invite user(s) </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
      <v-divider></v-divider>
    </div>
    <div class="users">
      <v-card class="table" flat>
        <v-data-table-virtual
          show-select
          item-value="uid"
          dense
          height="calc(100vh - 72px)"
          fixed-header=""
          :headers="headers"
          :items="filtered"
          :search="search"
          :loading="loading"
          v-model="selected"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click.stop="user = item"
              icon="mdi-chevron-right"
              variant="tonal"
              size="small"
              :color="user && user.uid === item.uid ? 'primary' : ''"
              density="comfortable"
              class="mr-2"
            ></v-btn>
          </template>
        </v-data-table-virtual>
      </v-card>
    </div>
    <v-navigation-drawer
      width="400"
      class="bg-smoke"
      v-if="user"
      location="right"
      permanent
    >
      <template v-slot:prepend>
        <v-btn
          style="position: absolute; top: 25px; left: -15px"
          @click="user = null"
          icon="mdi-close"
          size="small"
          density="comfortable"
        ></v-btn>
        <div class="pa-8 d-flex align-center">
          <div>
            <h1>{{ user.name }}</h1>
            <p>
              {{ user.email }} - Last login:
              {{ format(user.last_login) }}
            </p>
          </div>
          <v-spacer></v-spacer>

          <v-menu bottom left offset-y>
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-6"
                variant="text"
                size="small"
                icon="mdi-dots-vertical"
                v-bind="props"
                elevation="0"
              >
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="user.role != 'admin'"
                link
                class="px-6 py-2"
                @click="openSub"
              >
                Add subscription
              </v-list-item>
              <v-list-item
                link
                class="px-6 py-2"
                @click="deleteUsers([user.uid])"
              >
                Delete user
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="px-6 pb-3">
          <v-btn
            block=""
            :color="changes ? 'primary' : ''"
            flat
            :disabled="!changes"
            @click="update"
            >Save changes</v-btn
          >
        </div>
      </template>

      <v-divider></v-divider>

      <div class="pa-6">
        <div class="d-flex" style="gap: 10px">
          <v-text-field
            @input="changes = true"
            v-model="user.firstName"
            variant="solo"
            label="First name"
            flat
          ></v-text-field>
          <v-text-field
            @input="changes = true"
            v-model="user.lastName"
            variant="solo"
            flat
            label="Last name"
          ></v-text-field>
        </div>

        <div class="d-flex" style="gap: 10px">
          <v-text-field
            v-model="user.role"
            variant="solo"
            flat
            readonly
            label="Role"
          ></v-text-field>
          <v-select
            v-model="user.workspace"
            :items="workspaces"
            label="Workspace"
            item-value="uid"
            item-title="name"
            readonly
            variant="solo"
            flat
          ></v-select>
        </div>

        <div class="d-flex" style="gap: 10px" v-if="user.role === 'trial'">
          <v-text-field
            :model-value="format(user.trialStart)"
            label="Trial start"
            variant="solo"
            flat
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            :model-value="format(user.trialEnd)"
            label="Trial end"
          ></v-text-field>
          <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
            location="end"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                flat
                variant="text"
                color="primary"
                height="56"
                v-bind="props"
                >Extend</v-btn
              >
            </template>

            <v-card min-width="300">
              <v-date-picker v-model="extension"></v-date-picker>
              <v-card-actions>
                <v-btn variant="tonal" flat color="primary" @click="extend"
                  >Save</v-btn
                >
                <v-btn variant="text" @click="datepicker = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>

        <div
          class="d-flex"
          style="gap: 10px"
          v-if="user.role === 'freemium' || user.role === 'trial'"
        >
          <v-text-field
            type="number"
            v-model="user.imageCredits"
            label="Image credits"
          ></v-text-field>
          <v-text-field
            type="number"
            v-model="user.videoCredits"
            label="Video credits"
          ></v-text-field>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { db } from "@/firebase";
import moment from "moment";
import firebase from "firebase/app";
import { mapState } from "vuex";
import SubscriptionDialog from "./SubscriptionDialog.vue";

export default {
  components: {
    SubscriptionDialog,
  },
  data() {
    return {
      campaignId: null,
      campaigns: [],
      changes: false,
      datepicker: false,
      dialog: false,
      extension: null,
      filterFollowup: "",
      headers: [
        {
          title: "Name",
          sortable: true,
          value: "name",
        },
        {
          title: "Email",
          sortable: true,
          value: "email",
        },
        {
          title: "Workspace",
          sortable: true,
          value: "workspace_data.name",
        },
        {
          title: "",
          sortable: true,
          align: "end",
          value: "actions",
        },
      ],
      followup: ["Max", "Roger", "Chris"],
      invite: false,
      invites: [
        {
          email: "",
        },
      ],
      loading: true,
      mailshake_loading: false,
      menu: false,
      status: [
        {
          text: "Qualified",
          value: "qualified",
        },
        {
          text: "Unqualified",
          value: "unqualified",
        },
        {
          text: "Prospect",
          value: "prospect",
        },
        {
          text: "Client",
          value: "client",
        },
        {
          text: "Follow up later",
          value: "later",
        },
        {
          text: "Not converted",
          value: "not_client",
        },
      ],
      rolesOptions: ["admin", "member", "trial", "freemium"],
      selected: [],
      search: "",
      selectedRoles: [],
      sub_dialog: false,
      user: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    addresses() {
      let list = [];
      this.selected.forEach((u) => {
        list.push({
          emailAddress: u.email,
          fullName: u.name,
        });
      });
      return list;
    },
    filtered() {
      let filtered = this.users;

      if (this.search) {
        filtered = filtered.filter(
          (u) =>
            u.name.toLowerCase().includes(this.search.toLowerCase()) ||
            u.email.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      if (this.filterFollowup) {
        filtered = filtered.filter((u) => u.followup === this.filterFollowup);
      }

      if (this.selectedRoles.length > 0) {
        filtered = filtered.filter((u) => this.selectedRoles.includes(u.role));
      }
      return filtered;
    },
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.admin.users,
      workspaces: (state) => state.admin.workspaces,
    }),
  },
  methods: {
    async openSub() {
      this.$store.commit("setLoading", true);
      var getCustomer = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("stripeGetCustomer");

      const res = await getCustomer({ uid: this.user.uid });
      this.$store.commit("setLoading", false);

      let emptyAddress = {
        line1: "",
        line2: "",
        postal_code: "",
        city: "",
        state: "",
        country: null,
      };

      this.user.address = res.data.address ? res.data.address : emptyAddress;
      this.sub_dialog = true;
    },
    async addSubscription(uid) {
      var addSubscription = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("stripeAddSubscription");

      const res = await addSubscription({ uid: uid });
      console.log(res);
    },
    async superadmin(user) {
      console.log(user);
      var makesuperadmin = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("makesuperadmin");
      const link = await makesuperadmin({
        uid: user.uid,
        value: true,
      });
      console.log(link.data);
    },
    closeDialog(v) {
      if (v === "save") {
        this.fetchData();
      }

      this.dialog = false;
    },
    async deleteUsers(users) {
      let r = confirm("Are you sure?");

      if (r) {
        this.$store.commit("setLoading", true);

        let removeUsers = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("deleteUsers");
        await removeUsers(users);
        await this.$store.dispatch("fetchAdminUsers");
        this.selected = [];
        this.$store.commit("setLodaing", false);
      }
    },
    editItem(item) {
      this.user = item;
      this.dialog = true;
    },
    async extend() {
      let uid = this.user.uid;
      this.datepicker = false;
      await db
        .collection("users")
        .doc(this.user.uid)
        .update({ trialEnd: this.extension });
      await this.$store.dispatch("fetchAdminUsers");
      this.user = this.users.find((u) => u.uid === uid);
      this.$store.commit("setSnackbar", {
        text: "User updated",
        timeout: 2000,
        show: true,
      });
    },
    async fetchData() {
      const vm = this;
      this.loading = true;
      vm.users.sort((a, b) =>
        a.trialStart < b.trialStart ? 1 : b.trialStart < a.trialStart ? -1 : 0
      );
      vm.loading = false;

      var campaigns = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("mailshakeCampaigns");
      const res = await campaigns();

      this.campaigns = res.data;
    },
    format(d) {
      let t = d.toDate();
      return moment(t).format("DD-MM-YYYY");
    },
    getDate(d) {
      const date = d.toDate();
      const month = date.getMonth();
      const day = date.getDate();
      const year = date.getFullYear();

      return `${day}-${month + 1}-${year}`;
    },
    getCampaigns(user) {
      let list = this.campaigns;

      if (user.mailshake_campaigns) {
        list = list.filter((c) => {
          !user.mailshake_campaigns.includes(c.id);
        });
      }
      return list;
    },
    async mailshake() {
      this.mailshake_loading = true;
      var mailshake = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("mailshake");
      const res = await mailshake({
        campaignId: this.campaignId.id,
        addresses: this.addresses,
      });
      if (res.data === 200) {
        this.mailshake_loading = false;
        this.menu = false;
        alert("SUCCESSFULLY ADDED TO MAILSHAKE");
        for (let i of this.selected) {
          db.collection("users")
            .doc(i.uid)
            .update({
              mailshake_campaigns: firebase.firestore.FieldValue.arrayUnion(
                this.campaignId
              ),
            })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
        this.$store.dispatch("fetchUsers");
      } else if (res.data === 500) {
        alert("something went wrong. Ask Max");
      }
      this.selected = [];
      this.campaignId = null;
    },
    showSnackbar(val) {
      this.$emit("snackbar", val);
    },
    async sendInvites() {
      this.invite = false;
      await Promise.all(
        this.invites.map(async (i) => {
          if (i.email) {
            await db.collection("mail").add({
              to: i.email,
              from: "Brainsight <info@brainsight.app>",
              template: {
                name: "admin-invite",
                data: {
                  name: `${this.userProfile.firstName} from Brainsight`,
                },
              },
            });
          }
        })
      );
      this.$store.commit("setSnackbar", {
        text: "Invites sent",
        timeout: 2000,
        show: true,
      });
      this.invites = [{ email: "" }];
    },
    updateColumns() {
      db.collection("users")
        .doc(this.$store.state.users.userProfile.uid)
        .update({
          superadmin_columns: this.headers,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },

    async update() {
      console.log(this.user);
      this.user.name = this.user.firstName + " " + this.user.lastName;
      await db.collection("users").doc(this.user.uid).update(this.user);
      this.$store.dispatch("fetchAdminUsers");
      this.$store.commit("setSnackbar", {
        text: "User updated",
        timeout: 2000,
        show: true,
      });
      this.changes = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
