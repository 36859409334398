import { db, auth } from "@/firebase";
import firebase from "firebase/";
import router from "@/router/index";
import store from "@/store";
const heatmaps = {
  state: {
    heatmaps: [],
    last: null,
    end: false,
    samples: [],
    tags: [],
    loading: false,
    query: {
      search: "",
      tags: [],
      owned_by: [],
      application: "",
      type: [],
    },
    compare: [],
    compareSheet: false,
  },
  mutations: {
    compare(state, val) {
      if (val.value) {
        if (state.compare.length < 3) {
          state.compare.push(val.uid);
        }
        if (state.compare.length > 0) {
          state.compareSheet = true;
        }
      } else {
        state.compare.splice(state.compare.indexOf(val.uid), 1);
        if (state.compare.length === 0) {
          state.compareSheet = false;
          console.log("closing sheet");
        }
      }
    },
    cleanCompare(state) {
      state.compare = [];
    },
    closeCompare(state) {
      state.compareSheet = false;
    },
    setHeatmapsLoading(state, val) {
      state.loading = val;
    },
    setSamples(state, val) {
      state.samples = val;
    },
    addHeatmaps(state, val) {
      state.heatmaps = state.heatmaps.concat(val);
    },
    setHeatmaps(state, val) {
      state.heatmaps = val;
    },
    setLast(state, val) {
      state.last = val;
    },
    setEnd(state, val) {
      state.end = val;
    },
    resetQuery(state) {
      state.query = {
        search: "",
        tags: [],
        owned_by: [],
        application: "",
        type: [],
      };
    },
    removeHeatmap(state, val) {
      let index = state.heatmaps.findIndex((h) => h.uid === val);
      state.heatmaps.splice(index, 1);
      store.commit("removeProjectHeatmap", val);
    },
    updateTextInAd(state, val) {
      let i = state.heatmaps.findIndex((h) => h.uid === val.uid);
      state.heatmaps[i].text_in_ad = val.value;
    },
    updateHeatmap(state, val) {
      let index = state.heatmaps.findIndex((h) => h.uid === val.uid);
      if (router.currentRoute.value.path.includes("/project/")) {
        store.commit("updateProjectHeatmap", val);
      }

      if (index < 0) {
        console.log("adding item");
        state.heatmaps.unshift(val);
        index = state.heatmaps.findIndex((h) => h.uid === val.uid);
      }
      if (val.template_scores) {
        state.heatmaps[index].template_scores.display =
          val.template_scores.display;
        state.heatmaps[index].template_scores.social =
          val.template_scores.social;
        state.heatmaps[index].template_scores.ooh = val.template_scores.ooh;
      }
      state.heatmaps[index].attention_scores = val.attention_scores;
      state.heatmaps[index].thumbnail = val.thumbnail;
      state.heatmaps[index].attention_scores = val.attention_scores;
      state.heatmaps[index].status = val.status;
      state.heatmaps[index].application = val.application;
      state.heatmaps[index].clarity_score_percentile =
        val.clarity_score_percentile;
      state.heatmaps[index].heatmap = val.heatmap;
      state.heatmaps[index].imageURL = val.imageURL;
      state.heatmaps[index].heatmapURL = val.heatmapURL;
      state.heatmaps[index].mask = val.mask;
      state.heatmaps[index].peekthrough = val.peekthrough;
      state.heatmaps[index].peekthroughURL = val.peekthroughURL;
      state.heatmaps[index].gazeplot = val.gazeplot;
      state.heatmaps[index].public = val.public;
      state.heatmaps[index].tags = val.tags;
      state.heatmaps[index].title = val.title;
      state.heatmaps[index].metrics = val.metrics;
      state.heatmaps[index].uid = val.uid;
      state.heatmaps[index].combined = val.combined;
      if (val.logo_frames) {
        state.heatmaps[index].logo_frames = val.logo_frames;
      }
      if (val.logo_tracking_status) {
        state.heatmaps[index].logo_tracking_status = val.logo_tracking_status;
      }
      if (val.logo_tracking_video) {
        state.heatmaps[index].logo_tracking_video = val.logo_tracking_video;
      }
    },
    setTags(state, val) {
      state.tags = val;
    },
  },
  actions: {
    async fetchQueryHeatmaps({ state, commit }) {
      commit("setLoading", true);
      var queryHeatmaps = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("queryHeatmaps");
      const res = await queryHeatmaps(state.query);

      commit("setHeatmaps", JSON.parse(res.data));
      commit("setLast", null);
      commit("setLoading", false);
    },

    async fetchHeatmaps({ commit, state, rootState }) {
      return new Promise(async (resolve, reject) => {
        commit("setHeatmapsLoading", true);

        let superadmin = false;
        if (rootState.users.userProfile.superadmin) {
          superadmin = true;
        }
        let member = false;
        if (
          rootState.users.userProfile.role === "member" ||
          rootState.users.userProfile.role === "admin"
        ) {
          member = true;
        }
        let heatmaps = [];
        let query = null;
        if (state.last) {
          query = db
            .collection("heatmaps")
            .where("requested_by", "==", auth.currentUser.uid)
            .where("workspace", "==", rootState.users.userProfile.workspace)
            .where("hidden", "==", false)
            .orderBy("created_at", "desc")
            .startAfter(state.last);

          if (superadmin) {
            query = db
              .collection("heatmaps")
              .where("hidden", "==", false)
              .orderBy("created_at", "desc")
              .startAfter(state.last);
          } else if (member && rootState.users.userProfile.workspace != "") {
            query = db
              .collection("heatmaps")
              .where("workspace", "==", rootState.users.userProfile.workspace)
              .where("hidden", "==", false)
              .orderBy("created_at", "desc")
              .startAfter(state.last);
          }
        } else {
          query = db
            .collection("heatmaps")
            .where("requested_by", "==", auth.currentUser.uid)
            .where("workspace", "==", rootState.users.userProfile.workspace)
            .where("hidden", "==", false)
            .orderBy("created_at", "desc");

          if (superadmin) {
            query = db
              .collection("heatmaps")
              .where("hidden", "==", false)
              .orderBy("created_at", "desc");
          } else if (member && rootState.users.userProfile.workspace != "") {
            query = db
              .collection("heatmaps")
              .where("workspace", "==", rootState.users.userProfile.workspace)
              .where("hidden", "==", false)
              .orderBy("created_at", "desc");
          }
        }

        let querySnapshot = await query.limit(50).get();

        querySnapshot.docs.map((doc) => {
          heatmaps.push(doc.data());
        });
        if (querySnapshot.docs.length > 0) {
          commit("setLast", querySnapshot.docs[querySnapshot.docs.length - 1]);
        } else {
          commit("setEnd", true);
        }

        commit("addHeatmaps", heatmaps);
        commit("setHeatmapsLoading", false);
        resolve("Heatmaps loaded");
      });
    },
    async deleteHeatmap({ dispatch }, val) {
      await db.collection("heatmaps").doc(val).delete();

      const querySnapshot = await db
        .collection("projects")
        .where("heatmaps", "array-contains", val)
        .get();

      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          await db
            .collection("projects")
            .doc(doc.id)
            .update({
              heatmaps: firebase.firestore.FieldValue.arrayRemove(val),
            });
        })
      );

      dispatch("fetchProjects");
      if (router.currentRoute.value.name === "Heatmap") {
        router.push("/heatmaps");
      }
    },
    fetchSamples({ commit }) {
      return new Promise((resolve) => {
        let heatmaps = [];

        let query = db
          .collection("heatmaps")
          .where("sample", "==", true)
          .where("public", "==", true);

        query
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().status === "done") {
                heatmaps.push(doc.data());
              }
            });
            heatmaps.sort((a, b) =>
              a.created_at < b.created_at
                ? 1
                : b.created_at < a.created_at
                  ? -1
                  : 0
            );
          })
          .then(() => {
            commit("setSamples", heatmaps);
            resolve("Samples loaded");
          })
          .catch((error) => {
            resolve("No heatmaps found" + error);
          });
      });
    },
    updateTextInAd({ commit }, val) {
      return db
        .collection("heatmaps")
        .doc(val.uid)
        .update({
          text_in_ad: val.value,
        })
        .then(() => {
          console.log("Document successfully updated!");
          commit("updateTextInAd", val);
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
  getters: {},
};

export default heatmaps;
