<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    max-width="500"
    offset-y
  >
    <template v-slot:activator="{ props }">
      <v-btn variant="outlined" height="40" v-bind="props"
        ><v-icon size="small" class="mr-1" color="warning">mdi-filter</v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="pa-4">
        <v-text-field
          filled
          density="compact"
          label="Title"
          bg-color="smoke"
          type="text"
          hide-details="true"
          v-model="$store.state.heatmaps.query.search"
          flat
          variant="solo"
          clearable
          prepend-inner-icon="mdi-magnify"
          class="mb-2"
        ></v-text-field>

        <v-autocomplete
          prepend-inner-icon="mdi-tag-outline"
          :items="$store.state.heatmaps.tags"
          v-if="$store.state.heatmaps.tags.length > 0"
          v-model="$store.state.heatmaps.query.tags"
          height="40"
          variant="solo"
          label="Filter on tags"
          bg-color="smoke"
          hide-details="true"
          flat
          chips
          multiple
          small-chips
          density="compact"
          clearable
          deletable-chips
          :menu-props="{ rounded: true }"
          class="mb-2"
        ></v-autocomplete>

        <v-autocomplete
          v-model="$store.state.heatmaps.query.owned_by"
          prepend-inner-icon="mdi-account-outline"
          :items="$store.state.users.users"
          v-if="$store.state.users.users.length > 1"
          item-title="name"
          item-value="uid"
          multiple
          variant="solo"
          flat
          height="40"
          density="compact"
          label="Owned by"
          bg-color="smoke"
          hide-details="true"
          clearable
          :menu-props="{ rounded: true }"
          class="mb-2"
        ></v-autocomplete>

        <v-select
          v-if="$store.state.defaults.defaults.heatmap"
          v-model="$store.state.heatmaps.query.application"
          variant="solo"
          density="compact"
          flat
          height="40"
          bg-color="smoke"
          label="Filter on type"
          hide-details="true"
          clearable
          :items="$store.state.defaults.defaults.heatmap.types"
          prepend-inner-icon="mdi-filter-outline"
          :menu-props="{ rounded: true, offsetY: true }"
          class="mb-2"
        ></v-select>

        <v-btn-toggle
          v-if="
            (subscription.active && subscription.tier === 'business') ||
            workspace.beta
          "
          v-model="$store.state.heatmaps.query.type"
          density="compact"
          multiple
          variant="outlined"
          style="width: 100%; font-size: 10pt"
        >
          <v-btn width="50%" value="image" density="compact">
            <v-icon small>mdi-image-outline</v-icon> Images
          </v-btn>
          <v-btn width="50%" value="video" density="compact">
            <v-icon small>mdi-video-outline</v-icon> Videos
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn variant="text" @click="menu = false"> Cancel </v-btn>
        <v-btn
          variant="flat"
          color="warning"
          @click="
            $store.dispatch('fetchQueryHeatmaps');
            menu = false;
          "
          >Filter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
      loading: (state) => state.loading,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tagStore: (state) => state.heatmaps.tags,
      subscription: (state) => state.subscription.subscription,
      userStore: (state) => state.users.users,
    }),
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
