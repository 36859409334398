<template>
  <v-dialog :model-value="dialog" width="auto">
    <v-card width="400">
      <div class="pa-4 bg-primary">
        <p style="font-size: 14pt">Add subscription</p>
        <p style="font-size: 10pt">{{ user.email }}</p>
      </div>

      <div class="pa-4" v-if="stripe">
        <div>
          <div>
            <v-text-field
              class="mb-2"
              variant="solo"
              flat
              bg-color="smoke"
              label="Address line 1"
              hide-details
              v-model="user.address.line1"
            ></v-text-field>
            <v-text-field
              variant="solo"
              flat
              class="mb-2"
              bg-color="smoke"
              label="Address line 2"
              hide-details
              v-model="user.address.line2"
            ></v-text-field>
            <v-text-field
              variant="solo"
              flat
              class="mb-2"
              bg-color="smoke"
              label="Postal code"
              hide-details
              v-model="user.address.postal_code"
            ></v-text-field>
          </div>
          <div
            class="mb-2"
            style="display: grid; grid-template-columns: 1fr 1fr; gap: 5px"
          >
            <v-text-field
              variant="solo"
              flat
              bg-color="smoke"
              label="State"
              hide-details
              v-model="user.address.state"
            ></v-text-field>
            <v-text-field
              variant="solo"
              hide-details
              flat
              bg-color="smoke"
              label="City"
              v-model="user.address.city"
            ></v-text-field>
          </div>
          <v-autocomplete
            hide-details
            class="mb-2"
            :items="countries"
            item-value="alpha-2"
            item-title="name"
            label="Country"
            variant="solo"
            flat
            bg-color="smoke"
            v-model="user.address.country"
          >
          </v-autocomplete>
        </div>
        <v-select
          :items="stripe.prices"
          item-value="id"
          item-title="price_name"
          label="Subscription"
          variant="solo"
          flat
          hide-details
          bg-color="smoke"
          v-model="price"
          class="mb-2"
        >
        </v-select>
        <v-select
          :items="stripe.coupons"
          item-value="id"
          item-title="name"
          variant="solo"
          flat
          hide-details
          bg-color="smoke"
          label="Coupon"
          v-model="coupon"
        >
        </v-select>
      </div>
      <v-divider></v-divider>
      <template v-slot:actions>
        <v-btn text="Close" @click="$emit('close')"></v-btn>
        <v-btn
          :loading="loading"
          text="Save"
          @click="save"
          variant="flat"
          :disabled="
            !price ||
            !user ||
            !user.address.line1 ||
            !user.address.city ||
            !user.address.postal_code ||
            !user.address.state ||
            !user.address.country
          "
          color="primary"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import countries from "@/js/stripe_countries.js";

export default {
  props: {
    dialog: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      countries: countries,
      coupon: null,

      loading: false,
      price: null,
      wait: false,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.admin.users,
      stripe: (state) => state.admin.stripe,
    }),
  },
  methods: {
    async save() {
      this.loading = true;
      await this.$store.dispatch("addSubscription", {
        coupon: this.coupon,
        price: this.price,
        uid: this.user.uid,
        address: this.user.address,
      });
      this.loading = false;
      this.$emit("close");
    },
  },
};
</script>
