<template>
  <div>
    <v-bottom-sheet
      transition="dialog-bottom-transition "
      :model-value="uploads_dialog"
      persistent
      inset
      opacity="0.6"
    >
      <v-card width="100%" class="bg-smoke">
        <div class="bar bg-primary">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="text"
            class="text-white"
            @click="close"
            >Go back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="warning--text"
            variant="flat"
            @click="createHeatmaps"
            >Generate <b class="mx-1"> {{ uploads.length }}</b> heatmaps</v-btn
          >
        </div>
        <v-progress-linear
          color="smoke"
          :indeterminate="uploading || uploads.length === 0"
          :height="uploading || uploads.length === 0 ? 4 : 1"
        ></v-progress-linear>

        <div class="uploads">
          <div v-for="(upload, index) in indexedItems" :key="index">
            <v-card width="350" elevation="0">
              <v-btn
                style="z-index: 2; position: absolute; top: 10px; right: 10px"
                variant="tonal"
                size="large"
                @click="deleteItem(index)"
                icon="mdi-delete"
              >
              </v-btn>

              <v-img
                :height="200"
                :width="350"
                :aspect-ratio="16 / 9"
                :src="`${upload.thumbnail ? upload.thumbnail : upload.image}`"
                v-if="upload && upload.type === 'image'"
              >
              </v-img>
              <div
                v-else-if="upload.type === 'video'"
                style="
                  height: 200px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon x-large color="primary">mdi-video</v-icon>
              </div>
              <v-divider v-if="upload.type === 'video'"></v-divider>
              <v-progress-linear
                v-if="upload.upload_percentage < 100"
                value="15"
                color="primary"
              ></v-progress-linear>
              <div class="px-4 pb-8 pt-4">
                <v-form ref="form" v-model="uploads[index].valid">
                  <v-text-field
                    class=""
                    label="Title"
                    hide-details
                    filled
                    background-color="white"
                    flat
                    v-model="uploads[index].title"
                  ></v-text-field>
                  <div class="d-flex align-center mt-4">
                    <v-select
                      filled
                      background-color="white"
                      label="Project"
                      multiple
                      hide-details
                      clearable
                      :items="projects"
                      item-value="uid"
                      item-text="title"
                      v-model="uploads[index].projectIds"
                      :menu-props="{ rounded: true, offsetY: true }"
                    ></v-select>
                  </div>
                  <div class="d-flex align-center mt-4" v-if="defaults.heatmap">
                    <v-select
                      filled
                      :items="heatmapTypes"
                      background-color="white"
                      label="Type"
                      :hide-details="uploads[index].application"
                      ref="required"
                      required
                      :rules="[(v) => !!v || 'Item is required']"
                      :menu-props="{ rounded: true, offsetY: true }"
                      v-model="uploads[index].application"
                      @input="updateApplication(index)"
                    ></v-select>
                  </div>

                  <div
                    class="d-flex align-center mt-4"
                    v-if="uploads[index].application === 'UX Design'"
                  >
                    <v-select
                      :items="device"
                      label="Device"
                      filled
                      ref="required"
                      :required="uploads[index].application === 'UX Design'"
                      :rules="[(v) => !!v || 'Item is required']"
                      v-model="uploads[index].device"
                      hide-details=""
                      :menu-props="{ rounded: true, offsetY: true }"
                    ></v-select>
                  </div>

                  <div
                    class="d-flex align-center mt-4"
                    v-if="upload.application === 'UX Design'"
                  >
                    <v-select
                      :items="countries"
                      label="Country"
                      item-value="value"
                      item-title="text"
                      filled
                      v-model="uploads[index].country"
                      hide-details=""
                      :menu-props="{ rounded: true, offsetY: true }"
                    ></v-select>
                  </div>
                  <div
                    class="d-flex align-center mt-4"
                    v-if="uploads[index].application === 'UX Design'"
                  >
                    <v-select
                      :items="industries"
                      label="Industry"
                      item-value="value"
                      item-title="text"
                      filled
                      v-model="uploads[index].industry"
                      hide-details=""
                      :menu-props="{ rounded: true, offsetY: true }"
                    ></v-select>
                  </div>
                  <div
                    class="d-flex align-center mt-4"
                    v-if="uploads[index].application === 'Display Ads'"
                  >
                    <v-select
                      :items="sizes"
                      label="Dimensions"
                      filled
                      ref="required"
                      :required="uploads[index].application === 'Display Ads'"
                      :rules="[(v) => !!v || 'Item is required']"
                      background-color="white"
                      v-model="uploads[index].dimensions"
                      hide-details=""
                      :menu-props="{ rounded: true, offsetY: true }"
                    ></v-select>
                  </div>
                </v-form>
              </div>
              <v-divider v-if="uploads.length > 1"></v-divider>
              <v-menu
                v-if="uploads.length > 1"
                v-model="uploads[index].menu"
                :close-on-content-click="false"
                :nudge-width="100"
                offset-x
                top
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    block
                    text
                    height="46"
                    @click="updateSettings(upload.id)"
                    >Copy settings<v-icon small class="ml-2"
                      >mdi-content-copy</v-icon
                    ></v-btn
                  >
                </template>
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-card>
                      <v-data-table
                        :headers="headers2"
                        :items="settings"
                        hide-default-footer
                        item-title="text"
                        return-object
                        show-select
                        v-model="selectedSettings"
                      ></v-data-table>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          variant="text"
                          @click="uploads[index].menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn color="primary" @click="next" text> Next </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-card>
                      <v-data-table
                        class="overflow-auto"
                        :headers="headers"
                        :items="indexedItems"
                        hide-default-footer
                        :items-per-page="100"
                        show-select
                        return-object
                        v-model="selected"
                        item-title="text"
                        height="500"
                      ></v-data-table>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          variant="text"
                          @click="uploads[index].menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          variant="text"
                          @click="copySettings(upload.id)"
                        >
                          Copy
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-window-item>
                </v-window>
              </v-menu>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
// import moment from "moment";
import { mapState } from "vuex";
import { db, auth } from "@/firebase";
import firebase from "firebase/";
import display_config from "@/components/reportWizard/display_config";
import social_config from "@/components/reportWizard/social_config";
import ooh_config from "@/components/reportWizard/ooh_config";
import { uploads } from "@/mixins/uploads";

export default {
  mixins: [uploads],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
      projects: (state) => state.projects.projects,
    }),
    lastStep() {
      return this.uploads.length + 1;
    },
    heatmapTypes() {
      return this.defaults.heatmap.types;
    },
    indexedItems() {
      return this.uploads.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    allValid() {
      let valid = true;
      this.uploads.forEach((u) => {
        if (!u.valid) {
          valid = false;
        }
      });
      return valid;
    },
  },
  data() {
    return {
      display_config: display_config,
      social_config: social_config,
      ooh_config: ooh_config,
      headers: [
        {
          text: "To:",
          align: "start",
          sortable: false,
          value: "title",
        },
      ],
      headers2: [
        {
          text: "Copy:",
          align: "start",
          sortable: false,
          value: "text",
        },
      ],
      countries: [
        {
          value: "",
          text: "All countries",
        },
        {
          value: "de",
          text: "Germany",
        },
        {
          value: "nl",
          text: "Netherlands",
        },
        {
          value: "es",
          text: "Spain",
        },
        {
          value: "uk",
          text: "United Kingdom",
        },
        {
          value: "us",
          text: "United States",
        },
      ],
      industries: [
        {
          value: "",
          text: "All industries",
        },
        {
          value: "arts and entertainment",
          text: "Arts & entertainment",
        },
        {
          value: "vehicles",
          text: "Automotive & vehicles",
        },
        {
          value: "computers electronics and technology",
          text: "Computers, electronics & technology",
        },
        {
          value: "e-commerce and shopping",
          text: "E-commerce & shopping",
        },
        {
          value: "finance",
          text: "Finance",
        },
        {
          value: "food and beverages",
          text: "Food & beverages",
        },
        {
          value: "home and garden",
          text: "Home & garden",
        },
        {
          value: "lifestyle",
          text: "Lifestyle",
        },
        {
          value: "news and media",
          text: "News & media",
        },
        {
          value: "sports",
          text: "Sports",
        },
        {
          value: "travel",
          text: "Travel",
        },
      ],
      sizes: [
        "300x250",
        "728x90",
        "160x600",
        "300x600",
        "970x90",
        "970x250",
        "320x50",
        "320x100",
        "300x200",
      ],
      device: ["Mobile", "Desktop"],
      loading: true,
      load: false,
      menu: false,
      selected: [],
      selectedSettings: [],
      settings: [],
      snapshot: "",
      step: 1,
      title: "",
      uploading: false,
      uploadPercentage: 0,
      valid: false,
    };
  },
  watch: {
    uploads: {
      handler: function (val) {
        if (val.length > 0 && this.step === 0) {
          this.step = 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.uploadPercentage = 0;
    this.uploading = false;
    this.uploads = [];
    this.uploads_dialog = false;
    this.load = false;
    console.log("closing dialog");
  },
  methods: {
    getSnapshot(val) {
      this.snapshot = val;
    },
    close() {
      this.uploads_dialog = false;
      this.uploadPercentage = 0;
      this.uploading = false;
      this.uploads = [];
      this.load = false;
      console.log("closing");
    },
    copySettings(i) {
      console.log(this.selected);
      console.log(this.selectedSettings);
      this.selected.forEach((u) => {
        this.selectedSettings.forEach((s) => {
          if (s.text === "Type") {
            this.uploads[u.id].application = s.value;
          } else if (s.text === "Country") {
            this.uploads[u.id].country = s.value;
          } else if (s.text === "Industry") {
            this.uploads[u.id].industry = s.value;
          } else if (s.text === "Device") {
            this.uploads[u.id].device = s.value;
          } else if (s.text === "Dimensions") {
            this.uploads[u.id].dimensions = s.value;
          } else if (s.text === "Project") {
            this.uploads[u.id].projectIds = s.value;
          }
        });
      });
      this.step = 1;
      this.uploads[i].menu = false;
      this.selectedSettings = [];
      this.selected = [];
      this.settings = [];
    },
    createHeatmaps() {
      this.uploads.forEach((u, index) => {
        this.$refs.form[index].validate();
      });

      if (this.allValid) {
        this.uploading = true;
        this.$store.commit("setUploading", {
          values: [],
          show: true,
          timeout: -1,
        });
        for (let x in this.uploads) {
          this.uploadFiles(this.uploads[x]);
        }
        this.uploads_dialog = false;
      }
    },
    deleteItem(i) {
      if (this.uploads.length === 1) {
        this.close();
      } else {
        this.uploads.splice(i, 1);
      }
    },
    getTemplates(d) {
      return new Promise((resolve) => {
        let ids = {
          ooh_ids: [],
          display_ids: [],
          social_ids: [],
        };

        if (d.application === "Display Ads") {
          const dimensions = d.dimensions.split("x");
          const width = Number(dimensions[0]);
          const height = Number(dimensions[1]);
          const templates = this.display_config.filter(
            (t) => t.width === width && t.height === height
          );
          templates.forEach((t) => ids.display_ids.push(t.id));
        } else if (d.application === "Social media") {
          this.social_config.forEach((i) => ids.social_ids.push(i.id));
        } else if (d.application === "(Digital) Out Of Home") {
          this.ooh_config.forEach((i) => ids.ooh_ids.push(i.id));
        }

        resolve(ids);
      });
    },
    // loadStart() {
    //   console.log("start");
    // },
    // loaded() {
    //   console.log("loaded");
    // },
    next() {
      this.step = 2;
    },
    // nextStep() {
    //   this.$refs.form[this.step - 1].validate();
    //   let displayCheck =
    //     (this.uploads[this.step - 1].application === "Display Ads" &&
    //       this.uploads[this.step - 1].dimensions) ||
    //     this.uploads[this.step - 1].application != "Display Ads";
    //   if (this.uploads[this.step - 1].application && displayCheck) {
    //     this.step++;
    //   }
    // },
    updateApplication(i) {
      if (this.uploads[i].application === "UX Design") {
        this.uploads[i].country = "";
        this.uploads[i].industry = "";
      }
    },
    updateSettings(i) {
      this.settings = [];
      this.selectedSettings = [];
      this.selected = [];
      const upload = this.uploads[i];
      this.step = 1;

      if (upload.projectIds.length > 0) {
        this.settings.push({
          text: "Project",
          value: upload.projectIds,
        });
      }
      if (upload.application) {
        this.settings.push({
          text: "Type",
          value: upload.application,
        });
      }

      if (upload.application === "UX Design") {
        this.settings.push(
          {
            text: "Device",
            value: upload.device,
          },
          {
            text: "Country",
            value: upload.country,
          },
          {
            text: "Industry",
            value: upload.industry,
          }
        );
      }

      if (upload.application === "Display Ads") {
        this.settings.push({
          text: "Dimensions",
          value: upload.dimensions,
        });
      }
    },
    async uploadFiles(upload) {
      console.log(upload);
      if (upload.scrolling_video) {
        var getScrollingVideo = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("getScrollingVideo");
        await getScrollingVideo(upload);
        return;
      }
      this.$store.commit("scroll", true);
      const vm = this;
      var fileName = upload.original.name
        ? upload.original.name
        : `${upload.title}.${upload.extension}`;
      var fileExtension = fileName.split(".").pop();
      var storageRef = firebase.storage().ref();
      let path;
      let device = upload.device ? upload.device.toLowerCase() : "";
      let type = "";

      // The type is defined to calculate the Clarity score:
      switch (upload.application) {
        case "Display Ads":
          type = "DISPLAY";
          break;
        case "(Digital) Out Of Home":
          type = "AD";
          break;
        case "Social media":
          type = "AD";
          break;
        case "UX Design":
          type = "UX";
          break;
      }
      let country = upload.country ? upload.country : "";
      let condition = type === "UX" ? "viewport" : "";
      let industry = upload.industry ? upload.industry : "";

      const docRef = await db.collection("heatmaps").add({
        application: upload.application ? upload.application : "",
        audit: upload.audit ? upload.audit : false,
        areas_of_interest: [],
        created_at: new Date(),
        country: country,
        condition: condition,
        dimensions: upload.dimensions ? upload.dimensions : "",
        device: device,
        duration: upload.type === "video" ? upload.duration : null,
        extension: fileExtension,
        heatmap: "",
        frames:
          (upload.application === "Display Ads" && upload.type === "video") ||
          (upload.application === "Social media" && upload.type === "video") ||
          (upload.application === "(Digital) Out Of Home" &&
            upload.type === "video"),
        frames_children: [],
        image: "",
        hidden: false,
        industry: industry,
        logo_tracking: upload.type === "video",
        logo_tracking_status: null,
        peekthrough: "",
        public: false,
        readability: null,
        requested_by: vm.userProfile.uid,
        requested_by_name: vm.userProfile.name,
        requested_by_email: vm.userProfile.email,
        email_sent: false,
        reports: [],
        status: "requested",
        tags: [],
        tempFileName: fileName,
        template_scores: null,
        thumbnail: "",
        timeline: 0,
        title: upload.title,
        type: upload.type,
        workspace: vm.userProfile.workspace,
      });

      this.$store.commit("addUploading", {
        value: 0,
        uid: docRef.id,
      });

      const templates = await this.getTemplates(upload);

      await db.collection("heatmaps").doc(docRef.id).update({
        uid: docRef.id,
        templates: templates,
        template_scores: {},
      });

      if (upload.projectIds.length > 0) {
        const vm = this;
        vm.projects.forEach((p) => {
          if (
            upload.projectIds.includes(p.uid) &&
            !p.heatmaps.includes(docRef.id)
          ) {
            var projectRef = db.collection("projects").doc(p.uid);
            projectRef.update({
              heatmaps: firebase.firestore.FieldValue.arrayUnion(
                `${docRef.id}`
              ),
            });
          } else if (
            !upload.projectIds.includes(p.uid) &&
            p.heatmaps.includes(docRef.id)
          ) {
            var ref = db.collection("projects").doc(p.uid);
            ref.update({
              heatmaps: firebase.firestore.FieldValue.arrayRemove(
                `${docRef.id}`
              ),
            });
          }
        });
        vm.$store.dispatch("fetchProjects");
      }

      if (upload.type === "image") {
        path = storageRef.child(
          `${auth.currentUser.uid}/${docRef.id}/${docRef.id}.${fileExtension}`
        );
      } else if (upload.type === "video") {
        path = storageRef.child(
          `${auth.currentUser.uid}/${docRef.id}/${docRef.id}.${fileExtension}`
        );
      }

      var uploadTask = path.put(upload.original);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progress < 100) {
            this.$store.commit("updateUploading", {
              value: progress,
              uid: docRef.id,
            });
          }

          this.uploadPercentage = progress;
          this.uploads.upload_percentage = progress;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        async () => {
          var generate = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("generate");

          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            try {
              await generate({ uid: docRef.id });
            } catch (error) {
              console.log(error);
            } finally {
              console.log(
                "Generating: " + docRef.id + ". Available at: " + downloadURL
              );
              this.$store.commit("updateUploading", {
                value: 100,
                uid: docRef.id,
              });
            }
          });

          upload.extension = fileExtension;
          upload.uid = docRef.id;
          console.log(upload);
          if (this.$route.name != "Project" && this.$route.name != "Heatmaps") {
            this.$router.push("/heatmaps");
          }
        }
      );

      console.log(upload);

      var userRef = db.collection("users").doc(auth.currentUser.uid);

      if (upload.type === "image") {
        userRef.update({
          imageHeatmaps: firebase.firestore.FieldValue.increment(1),
        });
      } else if (upload.type === "video") {
        userRef.update({
          videoHeatmaps: firebase.firestore.FieldValue.increment(1),
        });
      }

      if (
        vm.userProfile.workspace &&
        (vm.userProfile.role === "member" || vm.userProfile.role === "admin")
      ) {
        var workspaceRef = db
          .collection("workspaces")
          .doc(vm.userProfile.workspace);
        if (upload.type === "image") {
          workspaceRef.update({
            imageHeatmaps: firebase.firestore.FieldValue.increment(1),
            imageCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "image");
        } else if (upload.type === "video") {
          workspaceRef.update({
            videoHeatmaps: firebase.firestore.FieldValue.increment(1),
            videoCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "video");
        }
      } else {
        if (upload.type === "image") {
          userRef.update({
            imageCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "image");
        } else {
          userRef.update({
            videoCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "video");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  padding: 15px 30px;
  display: flex;
  gap: 25px;
  position: sticky;
  top: 0;

  z-index: 3;
}
.uploads {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  gap: 25px;
}
</style>
